import React, { Component } from 'react'
import Layout from '../components/layout';
import Seo from "../components/SEO/Seo"

import "../styles/newsletter.scss"
import AutosizeInput from 'react-input-autosize';

import addToMailchimp from 'gatsby-plugin-mailchimp'




class Newsletter extends Component {

    updateInputValue = (input, event) => {
        const newState = {};
        newState[input] = event.target.value;
        this.setState(newState);
    };

    constructor(props) {
        super(props);
        this.state = {
            nombre: '',
            apellidos: '',
            email: '',
            mensaje: '',
            respuesta: '',
            isChecked: false,
        };
    }

    _handleSubmit = e => {
        e.preventDefault()

        addToMailchimp(this.state.email, {
            FNAME: this.state.nombre,
            LNAME: this.state.apellidos,

        }, this.state)
            .then(({ msg, result }) => {
                this.setState({ respuesta: result })
                this.setState({ mensaje: msg })
                if (result !== 'success') {
                    throw msg
                }
            })
            .catch(err => {
                this.setState({ mensaje: err })
            })
    }

    cambiarCheck = () => {
        this.setState(prevState => ({ isChecked: !prevState.isChecked }));
    }

    render() {
        return (
            <Layout>
                <Seo title="Suscríbete a nuestra Newsletter" pathname={this.props.location.pathname} />
                <div className="primera-seccion cuerpo-contacto container">
                    <h1><span className="subrayado">Suscríbete</span> a nuestra newsletter</h1>
                    <div className="caja-formulario">
                        <form className="form-contacto" method="post" action="#" onSubmit={this._handleSubmit}>
                            <div>
                                <label htmlFor="nombre">Dínos tu nombre,</label>
                                <AutosizeInput
                                    name="nombre"
                                    placeholder="nombre"
                                    required
                                    value={this.state.nombre}
                                    onChange={this.updateInputValue.bind(this, 'nombre')}
                                    style={{ fontFamily: "Now-black-light" }}
                                    inputStyle={{ fontFamily: "Now-black-light", fontWeight: "bold" }}
                                />
                            </div>
                            <div>
                                <label htmlFor="apellidos">tu apellido</label>
                                <AutosizeInput
                                    name="apellidos"
                                    placeholder="apellidos"
                                    required
                                    value={this.state.apellidos}
                                    onChange={this.updateInputValue.bind(this, 'apellidos')}
                                    style={{ fontFamily: "Now-black-light" }}
                                    inputStyle={{ fontFamily: "Now-black-light", fontWeight: "bold" }}
                                />
                            </div>
                            <div>
                                <label htmlFor="email">y tu email</label>
                                <AutosizeInput
                                    type="email"
                                    name="email"
                                    placeholder="mi email"
                                    required
                                    value={this.state.email}
                                    onChange={this.updateInputValue.bind(this, 'email')}
                                    style={{ fontFamily: "Now-black-light" }}
                                    inputStyle={{ fontFamily: "Now-black-light", fontWeight: "bold" }}
                                />
                            </div>
                            <br />
                            <div className="caja-checkbox">
                                <input
                                    type="checkbox"
                                    id="privacidad"
                                    name="privacidad"
                                    checked={this.state.isChecked}
                                    onChange={this.cambiarCheck}
                                />
                                <label htmlFor="privacidad" className="check-box"></label>
                                <label htmlFor="privacidad">He leído y acepto la política de privacidad</label>
                            </div>

                            <div className={`mensaje-formulario
                                ${!this.state.respuesta ? "" :
                                    this.state.respuesta !== "error" ? "correcto"
                                        : "error"
                                }                              
                            `} dangerouslySetInnerHTML={{ __html: this.state.mensaje }} />

                            <div className="icono-input">
                                <input type="submit" value="Enviar" className="boton-enviar" disabled={!this.state.isChecked} />
                            </div>
                            <p><small className="texto-recaptcha">Este sitio está protegido por reCAPTCHA y se aplican la <a href="https://policies.google.com/privacy">política de privacidad</a> y los <a href="https://policies.google.com/terms">términos del servicio</a> de Google.</small></p>
                        </form>
                    </div>
                </div>
            </Layout>
        )
    }
}


export default Newsletter